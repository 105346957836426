/**
 * @author Vahagn Lazyan.
 * @since 1.1.0
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {FilterCriteria, PagingOptions, SortingOptions, SortType} from '@synisys/idm-de-core-frontend';
import {MainEntity} from '@synisys/idm-de-service-client-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';

import {PortfolioRowModel} from './portfolio-row.model';
import {DynamicPortfolioService} from './dynamic-portfolio.service';
import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {ExtractionService} from '../../services';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';
import {Classifier} from '@synisys/idm-classifier-service-client-js';
import './portfolio.component.scss';

/**
 *
 * @implements {OnInit, OnDestroy}
 */
@Component({
             moduleId   : module.id + '',
             providers  : [DynamicPortfolioService],
             selector   : 'dynamic-portfolio',
             templateUrl: 'portfolio.component.html',
           })
@ControlMetadata({
                   template: `
        <dynamic-portfolio
            [id]="'%{id}'"
            [categorySystemName]="'%{categorySystemName}'"
            [columnSystemNames]="%{columnSystemNames}"
            [deFormName]="'%{deFormName}'"
            [pageLimit]="'%{pageLimit}'"
            [filterKeys]="%{filterKeys}"
            %{#defaultFilter} [defaultFilter]="%{defaultFilter}" %{/defaultFilter}>
        </dynamic-portfolio>
    `,
                 })
export class DynamicPortfolioComponent implements OnInit, OnDestroy {

  // Expose enums to use in template.
  public metaFieldType = MetaFieldType;

  @Input()
  public id: string;

  /**
   * Prefix for dynamic-de url.
   * @type {string}
   * @deprecated
   */
  @Input()
  public baseUrl: string; // todo remove when have major version

  /**
   * Category system name to render portfolio for.
   * @type {string}
   */
  @Input()
  public categorySystemName: string;

  /**
   * List of column system names.
   * @type {Array<string>}
   */
  @Input()
  public columnSystemNames: string[];

  /**
   * Form name in dynamic form kb.json, to open from portfolio.
   * @type {string}
   */
  @Input()
  public deFormName: string;

  /**
   *
   * @type {number}
   * @default
   */
  @Input()
  public formId = 0;

  /**
   * Key to get filter from {@link DynamicCommunicationService}.
   * @type {string}
   */
  @Input()
  public filterKeys: string[];

  /**
   * Initial default filter.
   * @type (FilterCriteria)
   */
  @Input()
  public defaultFilter: FilterCriteria;

  /**
   * Number of rows to render in portfolio.
   * @type {number}
   */
  @Input()
  public pageLimit = 0;

  /**
   * Permissons for loading portfolio items
   * @type {CategoryPermissionType}
   */

  @Input()
  public categoryPermissions: CategoryPermissionType[];

  /**
   * Indicator for current portfolio page.
   * @type {number}
   * @default
   * @protected
   */
  protected _currentPage = 0;

  /**
   * Is used to init view after all needed info is loaded.
   * @type {boolean}
   * @default
   * @protected
   */
  protected _isReady = false;

  // Properties and accessors for sorting components. Don't delete them.

  protected _sortField: string | undefined = undefined;
  protected _isAscending = false;
  protected _notificationBoxVisibility = false;

  private portfolioInit$: Subscription;

  constructor(protected dynamicPortfolioService: DynamicPortfolioService,
              private extractionService: ExtractionService) {
  }

  get sortField(): string | undefined {
    return this._sortField;
  }

  set sortField(value: string | undefined) {
    this._sortField = value;
  }

  get isAscending(): boolean {
    return this._isAscending;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(currentPage: number) {
    this._currentPage = currentPage;
    this.dynamicPortfolioService.updatePortfolio(new PagingOptions(this._currentPage, this.pageLimit));
  }

  get totalItemsCount(): number {
    return this.dynamicPortfolioService.totalItemsCount;
  }

  get isReady(): boolean {
    return this._isReady;
  }

  get pageTotalCount(): number {
    return this.dynamicPortfolioService.pageTotalCount;
  }

  get portfolioColumns(): MetaField[] {
    return this.dynamicPortfolioService.portfolioColumns;
  }

  get portfolioRows(): PortfolioRowModel[] {
    return this.dynamicPortfolioService.portfolioRows;
  }

  get notificationBoxVisibility(): boolean {
    return this._notificationBoxVisibility;
  }

  public ngOnInit(): void {
    this.portfolioInit$ = this.dynamicPortfolioService
                              .initFor(
                                this.categorySystemName,
                                this.columnSystemNames,
                                this.pageLimit,
                                this.filterKeys,
                                this.defaultFilter,
                                this.categoryPermissions)
                              .subscribe(() => {
                                this._isReady = true;
                              }, err => console.error(err));
  }

  public ngOnDestroy() {
    this.portfolioInit$.unsubscribe();
  }

  /**
   * Function used by sorting components.
   * @param {string} sortField
   * @param {boolean} sortAscending
   */
  public changeSorting(sortField: string, sortAscending: boolean): void {
    this._currentPage = 0;
    this._sortField = sortField;
    this._isAscending = sortAscending;

    const sortType: SortType = this._isAscending ? SortType.ASC : SortType.DESC;
    this.dynamicPortfolioService.sortingOptions = new SortingOptions(sortField, sortType);
    this.dynamicPortfolioService.updatePortfolio(new PagingOptions(this._currentPage, this.pageLimit));
  }

  /**
   * Navigates to date entry form.
   * @param {PortfolioRowModel} entityRow
   */
  public navigateToDeForm(entityRow: PortfolioRowModel): void {
    this.dynamicPortfolioService.navigateToDeForm(entityRow.entityInstanceId, this.deFormName, this.formId)
        .filter(value => value)
        .subscribe(() => {
          this._notificationBoxVisibility = true;
          setTimeout(() => this._notificationBoxVisibility = false, 3500);
        }, console.error);
  }

  /**
   * Gets name from nameMetaFields.
   * @param {any} value
   * @returns {string}
   */
  public getNameMeasure(value: MultilingualString | string): string {
    if (value instanceof MultilingualString) {
      return value.getValue(this.dynamicPortfolioService.currentLanguageId);
    }
    return value;
  }

  /**
   * Gets sort field name from {@link columnSystemNames}.
   * @param {MetaField} metaField
   * @returns {string}
   */
  public getSortField(metaField: MetaField): string | undefined {
    return this.dynamicPortfolioService.searchNames.get(metaField.getSystemName());
  }

  /**
   * Extracts {@link MainEntity} value to display in portfolio.
   * @param {MainEntity} mainEntity
   * @returns {number}
   */
  public extractMainEntityValue(mainEntity: MainEntity): number {
    return mainEntity && mainEntity.getInstanceId();
  }

  /**
   * Extracts {@link MainEntity} value considering that it is classifier.
   * Value for classifier considered to be its name.
   * @param {MainEntity} classifier
   * @param compoundCategorySystemName classifier's type
   * @returns Observable{string}
   */
  public extractClassifier(classifier: Classifier, compoundCategorySystemName: string): Observable<string> {
    return this.extractionService.extractNameFromClassifier(classifier, compoundCategorySystemName,
                                                            this.dynamicPortfolioService.currentLanguageId);
  }

  public extractMultiselect(classifiers: Classifier[], compoundSystemName: string): Observable<string> {
    return (!classifiers || classifiers.length === 0) ? Observable.of('') :
           this.extractionService.extractNameFromMultiSelect(classifiers, compoundSystemName,
                                                             this.dynamicPortfolioService.currentLanguageId);
  }

  /**
   * Extracts {@link MultilingualString} value with {@link currentLanguage} to display in portfolio.
   * @param {MultilingualString} mlString
   * @returns {string}
   */
  public extractMultilingualString(mlString: MultilingualString): string {
    return mlString.getValue(this.dynamicPortfolioService.currentLanguageId);
  }

  public extractImageDocument(documentId: number,
                              instanceId: number,
                              documentSystemName: MetaField): Observable<string> {
    return this.extractionService.extractImageDocument(documentId, instanceId, this.categorySystemName,
                                                       documentSystemName.getSystemName());
  }

  private getCellId(row: PortfolioRowModel, index: number): string {
    return this.id + '-' + row.entityInstanceId + '-' + index;
  }
}
