import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {DeService, SubEntity} from '@synisys/idm-de-service-client-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {
    KbService,
    MetaField,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {
    Validation,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {BaseTable, FormActions} from '../../models';
import {Map} from 'immutable';
import {isNil, memoize, MemoizedFunction} from 'lodash';
import {SisInlineCommunicationService} from './sis-inline-communication.service';
import {InlineSisTableSettingsComponent} from './settings';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import './inline-sis-table.component.scss';
import {ValidationDisplayType} from '@synisys/idm-validation-calculation-service-client-js/src/model/validation-display-type';
import {LocaleService} from '@synisys/skynet-store-locales-impl';
import {noop} from 'rxjs/util/noop';
import {LocaleInfo} from '@synisys/skynet-store-locales-api';

@Component({
    moduleId: module.id + '',
    selector: 'inline-sis-table',
    templateUrl: 'inline-sis-table.component.html',
    providers: [SisInlineCommunicationService],
})
@ControlMetadata({
    settings: {
        main: InlineSisTableSettingsComponent,
    },
    isFieldBound: true,
    template: `
        <inline-sis-table
            [id]="'%{id}'"
            [form]="form"
            [actions]="actions"
            [rowItems]="%{field}"
            [isReadonly]="%{isReadonly}"
            [subFormKey]="%{subFormKey}"
            [validations]="validations"
            [fieldName]="'%{field-name}'"
            [mainCategoryName]="category"
            [categoryName]="'%{field-category}'"
            [colSystemNames]="%{colSystemNames}"
            [isDeletable]="%{isDeletable}" [isAddable]="%{isAddable}"
            (onDocumentUploaded)="actions.submitNestedDocument($event.id, $event.entity, $event.documentField, form)"
            (validationEmitter)="%{validationEmittedFor}"
            (onDocumentDeleted)="actions.removeNestedDocument($event.id, form, $event.entity, $event.documentField)">
        </inline-sis-table>
    `,
    cellCount: 6,
    defaultActions: {
        validationEmittedFor: 'validationEmittedFor',
    },
})
export class InlineSisTableComponent extends BaseTable
    implements OnInit, OnChanges, OnDestroy {
    public readonly metaFieldType = MetaFieldType;
    public validationMessage: ((
        entityId: number,
        fieldName: string
    ) => string | undefined) &
        MemoizedFunction;
    @Input()
    public form: {entity: Entity};
    // TODO: replace this when specialized message is added for de
    public readonly datePlaceholderKey =
        'portfolio.filter.date.range.choose.date';
    private _onDocumentDeleted: EventEmitter<
        DocumentIdAndRow
    > = new EventEmitter<DocumentIdAndRow>();
    private _onDocumentUploaded: EventEmitter<
        DocumentIdAndRow
    > = new EventEmitter<DocumentIdAndRow>();
    private _id: string;
    private _subFormKey: string;
    private _colSystemNames: string[];
    private _isDeletable = true;
    private _actions: FormActions;
    private _rowItems: Entity[] | undefined;
    private _fieldName: string;
    private _mainCategoryName: string;
    private _categoryName: string;
    private _isAddable = true;
    private _languages: Language[];
    private _isReadonly = false;
    private _validations: Validation[] = [];
    private _validationMessages: Map<number, Map<string, string[]>> = Map();
    private _allMetaFields: MetaField[];
    private _deleteMessageKey: string;
    private _deleteMessageTitleKey: string;
    private _validationEmitter: EventEmitter<void> = new EventEmitter<void>();
    private _requiredMetaFieldIds: MetaFieldId[];

    constructor(
        private kbService: KbService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private classifierService: ClassifierService,
        private languageService: LanguageService,
        private deService: DeService,
        private validationService: ValidationService,
        public dialog: MatDialog,
        public localeService: LocaleService
    ) {
        super();
        this.validationMessage = memoize(
            this._validationMessage,
            validationCacheKeyResolver
        );
    }

    // <editor-fold desc="getters and setters">
    @Output()
    get onDocumentDeleted(): EventEmitter<DocumentIdAndRow> {
        return this._onDocumentDeleted;
    }

    set onDocumentDeleted(documentUploaded: EventEmitter<DocumentIdAndRow>) {
        if (!documentUploaded) {
            return;
        }
        this._onDocumentUploaded = documentUploaded;
    }

    @Output()
    get validationEmitter(): EventEmitter<void> {
        return this._validationEmitter;
    }

    set validationEmitter(validationEmitter: EventEmitter<void>) {
        if (!validationEmitter) {
            return;
        }
        this._validationEmitter = validationEmitter;
    }

    @Output()
    get onDocumentUploaded(): EventEmitter<DocumentIdAndRow> {
        return this._onDocumentUploaded;
    }

    set onDocumentUploaded(documentUploaded: EventEmitter<DocumentIdAndRow>) {
        if (!documentUploaded) {
            return;
        }
        this._onDocumentUploaded = documentUploaded;
    }

    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    get subFormKey(): string {
        return this._subFormKey;
    }

    @Input()
    set subFormKey(value: string) {
        this._subFormKey = value;
    }

    get colSystemNames(): string[] {
        return this._colSystemNames;
    }

    @Input()
    set colSystemNames(value: string[]) {
        this._colSystemNames = value;
    }

    get isDeletable(): boolean | undefined {
        return this._isDeletable;
    }

    @Input()
    set isDeletable(value: boolean | undefined) {
        this._isDeletable = value === undefined ? true : value;
    }

    get actions(): FormActions {
        return this._actions;
    }

    @Input()
    set actions(value: FormActions) {
        this._actions = value;
    }

    get rowItems(): Entity[] | undefined {
        return this._rowItems;
    }

    @Input()
    set rowItems(value: Entity[] | undefined) {
        this._rowItems = value;
    }

    get mainCategoryName(): string {
        return this._mainCategoryName;
    }

    @Input()
    set mainCategoryName(value: string) {
        this._mainCategoryName = value;
    }

    get categoryName(): string {
        return this._categoryName;
    }

    @Input()
    set categoryName(value: string) {
        this._categoryName = value;
    }

    get isAddable(): boolean {
        return this._isAddable;
    }

    @Input()
    set isAddable(value: boolean) {
        this._isAddable = value;
    }

    get validations(): Validation[] {
        return this._validations;
    }

    @Input()
    set validations(value: Validation[]) {
        this._validations = value;
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    get deleteMessageTitleKey(): string {
        return this._deleteMessageTitleKey;
    }

    @Input()
    set deleteMessageTitleKey(value: string) {
        this._deleteMessageTitleKey = value;
    }

    get deleteMessageKey(): string {
        return this._deleteMessageKey;
    }

    @Input()
    set deleteMessageKey(value: string) {
        this._deleteMessageKey = value;
    }

    get languages(): Language[] {
        return this._languages;
    }

    @Input()
    get fieldName(): string {
        return this._fieldName;
    }

    set fieldName(value: string) {
        this._fieldName = value;
    }

    // </editor-fold>

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.validations) {
            this.onValidation(changes.validations.currentValue);
        }
        if (
            changes.colSystemNames &&
            this.isReady &&
            !isNil(this._allMetaFields) &&
            !isNil(this._requiredMetaFieldIds)
        ) {
            this.digOutColumnsMetaFields(this._allMetaFields);
            this.handleHeader(this._requiredMetaFieldIds);
        }
    }

    public ngOnInit(): void {
        const kbData$ = this.kbService.getMetaFields(this.categoryName);
        const entity$ = <Observable<Entity>>(
            this.deService.createBlankSubEntity(this.categoryName)
        );
        const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();
        const languages$ = this.languageService.getInputLanguages();
        const requiredMetaFieldIds$: Observable<MetaFieldId[]> = this.validationService.getCategoryRequiredMetaFieldIds(
            this.categoryName
        );

        currentLanguage$
            .pipe(
                mergeMap((currentLanguage: Language) => {
                    this.currentLanguage = currentLanguage;
                    const localeInfo$ = this.localeService.getLocaleInfo(
                        currentLanguage.getId()
                    );
                    return zip(
                        kbData$,
                        entity$,
                        localeInfo$,
                        languages$,
                        requiredMetaFieldIds$
                    );
                }),
                map((data: InitData) => {
                    this._allMetaFields = data[0];
                    data[0] = this.digOutColumnsMetaFields(data[0]);
                    return data;
                }),
                map((data: InitData) => {
                    const [
                        ,
                        entity,
                        localeInfo,
                        languages,
                        metaFieldIds,
                    ] = data;
                    this._requiredMetaFieldIds = metaFieldIds;
                    this.handleHeader(metaFieldIds);
                    this.addItemIfEmpty(entity);
                    this.localeInfo = localeInfo;
                    this._languages = languages;
                    this.isReady = true;
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.log);
    }

    public inlineAdd(): void {
        this.deService
            .createBlankSubEntity(this.categoryName)
            .pipe(
                mergeMap((subEntity: SubEntity) => {
                    if (this._rowItems) {
                        const observables = [of(<void>undefined)];
                        subEntity.setId(this.getUniqueIdForSubEntity());
                        this._rowItems.push(subEntity);
                        return combineLatest(observables);
                    } else {
                        throw Error('row items were not been initialized');
                    }
                }),
                catchError((err, o) => {
                    console.error(err);
                    return o;
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                () => {},
                err => console.error(err)
            );
    }

    public openEditPopup(rowItem: Entity): void {
        throw Error('Operation not supported');
    }

    public openAddPopup(): void {
        this.deService
            .createBlankSubEntity(this.categoryName)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (subEntity: SubEntity) => {
                    subEntity.setId(this.getUniqueIdForSubEntity());
                    this.actions.openPopup(
                        this.form,
                        this.categoryName,
                        this.subFormKey,
                        subEntity
                    );
                },
                err => console.error(err)
            );
    }

    public onDocumentUpload(
        id: number,
        row: Entity,
        documentField: MetaField
    ): void {
        this.onDocumentUploaded.emit({
            id: id,
            entity: row,
            documentField: documentField.getSystemName(),
        });
    }

    public onDocumentDelete(
        id: number,
        row: Entity,
        documentField: MetaField
    ): void {
        this.onDocumentDeleted.emit({
            id: id,
            entity: row,
            documentField: documentField.getSystemName(),
        });
    }

    public removeAction(): void {
        super.removeAction();
        const id = this._rowToRemove.getId();
        this.columnMetaFields.forEach(colName => {
            this.validationMessage.cache.delete(
                validationCacheKeyResolver(id, colName.getSystemName())
            );
            this._validationMessages = this._validationMessages.delete(id);
        });
    }

    public getCellId(row: Entity, index: number): string {
        return this.id + '-' + row.getId() + '-' + index;
    }

    public isFieldRequired(systemName: string): boolean {
        return this._requiredMetaFieldIds.some(
            metaFieldId => metaFieldId.getSystemName() === systemName
        );
    }

    private _validationMessage(
        entityId: number,
        fieldName: string
    ): string | undefined {
        if (
            !this._validations ||
            !this._validationMessages.has(entityId) ||
            !this._validationMessages.get(entityId).has(fieldName)
        ) {
            return undefined;
        }
        return this._validationMessages
            .get(entityId)
            .get(fieldName)
            .join('\n');
    }

    private onValidation(validationsCurrent: Validation[] | undefined): void {
        if (validationsCurrent && validationsCurrent.length > 0) {
            const subEntityValidations = this.extractSubEntityValidations(
                validationsCurrent
            );
            const functionalValidations = this.extractFunctionalValidations(
                validationsCurrent
            );
            this._validationMessages = Map<
                number,
                Map<string, string[]>
            >().withMutations(mutable => {
                subEntityValidations.forEach((val: Validation) => {
                    let rowValidationMap = mutable.get(
                        val.getIdentity(),
                        Map<string, string[]>()
                    );
                    val.getRelatedMetaFieldIds().forEach(
                        (relatedMetaFieldId: MetaFieldId) => {
                            const fieldName = relatedMetaFieldId.getSystemName();
                            const Validations = rowValidationMap.get(
                                fieldName,
                                [] as string[]
                            );
                            Validations.push(
                                val
                                    .getMessage()
                                    .getValue(this.currentLanguage.getId())
                            );
                            rowValidationMap = rowValidationMap.set(
                                fieldName,
                                Validations
                            );
                        }
                    );
                    mutable.set(val.getIdentity(), rowValidationMap);
                });
                functionalValidations.forEach((val: Validation) => {
                    (val
                        .getValidationDisplay()
                        .getFields()
                        .get('fields')
                        ['rows'] as number[]).forEach(entityId => {
                        let rowMap = mutable.get(
                            entityId,
                            Map<string, string[]>()
                        );
                        (val
                            .getValidationDisplay()
                            .getFields()
                            .get('fields')
                            ['fields'] as string[]).forEach(fieldName => {
                            const Validations = rowMap.get(
                                fieldName,
                                [] as string[]
                            );
                            Validations.push(' ');
                            rowMap = rowMap.set(fieldName, Validations);
                        });
                        mutable.set(entityId, rowMap);
                    });
                });
            });
            if (subEntityValidations.length > 0) {
                this._validationEmitter.emit();
            }
            this._validations = subEntityValidations.concat(
                functionalValidations
            );
            this.validationMessage.cache.clear();
        } else if (validationsCurrent) {
            this._validations = [];
            this._validationMessages = Map();
            this.validationMessage.cache.clear();
        }
    }

    private digOutColumnsMetaFields(metaFields: MetaField[]): MetaField[] {
        if (this.colSystemNames && this.colSystemNames.length > 0) {
            this.columnMetaFields = [];
            for (const colName of this.colSystemNames) {
                const found = metaFields.find(
                    meta => meta.getSystemName() === colName
                );
                if (found) {
                    this.columnMetaFields.push(found);
                }
            }
        } else {
            this.columnMetaFields = metaFields.filter(
                (metaField: MetaField) => {
                    return (
                        metaField.getType() === MetaFieldType.DATE ||
                        metaField.getType() === MetaFieldType.CLASSIFIER ||
                        metaField.getType() === MetaFieldType.MAIN_ENTITY ||
                        metaField.getType() ===
                            MetaFieldType.MULTILINGUAL_STRING ||
                        metaField.getType() === MetaFieldType.DOCUMENT ||
                        metaField.getType() === MetaFieldType.STRING ||
                        metaField.getType() === MetaFieldType.BIG_DECIMAL ||
                        metaField.getType() === MetaFieldType.DECIMAL ||
                        metaField.getType() === MetaFieldType.INTEGER ||
                        metaField.getType() === MetaFieldType.ACCOUNTING ||
                        metaField.getType() === MetaFieldType.MONEY
                    );
                }
            );
        }
        return this.columnMetaFields;
    }

    private addItemIfEmpty(entity: Entity): void {
        if (!this.rowItems) {
            this.rowItems = [];
            this.rowItems.push(entity);
        }
    }

    private extractSubEntityValidations(
        validations: Validation[]
    ): Validation[] {
        return validations.filter((val: Validation) => {
            return val
                .getRelatedMetaFieldIds()
                .every((metaFieldId: MetaFieldId) =>
                    this.columnMetaFields.some(
                        (columnField: MetaField) =>
                            metaFieldId.getSystemName() ===
                                columnField.getSystemName() &&
                            metaFieldId.getMetaCategoryId().getSystemName() ===
                                columnField
                                    .getMetaFieldId()
                                    .getMetaCategoryId()
                                    .getSystemName()
                    )
                );
        });
    }

    private extractFunctionalValidations(
        validations: Validation[]
    ): Validation[] {
        return validations.filter(
            (val: Validation) =>
                val.getValidationDisplay() &&
                val.getValidationDisplay().getDisplayType() ===
                    ValidationDisplayType.FUNCTIONAL &&
                val.getRelatedMetaFieldIds().length === 1 &&
                val.getRelatedMetaFieldIds()[0].getSystemName() ===
                    this.fieldName &&
                val
                    .getRelatedMetaFieldIds()[0]
                    .getMetaCategoryId()
                    .getSystemName() === this.mainCategoryName
        );
    }
}

type InitData = [MetaField[], Entity, LocaleInfo, Language[], MetaFieldId[]];

export type DocumentIdAndRow = {
    id: number;
    entity: Entity;
    documentField: string;
};

function validationCacheKeyResolver(id: number, name: string): string {
    return `${id}_${name}`;
}
