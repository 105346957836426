import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DeService, MainEntity} from '@synisys/idm-de-service-client-js';
import {KbService, MetaCategoryId, MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {ExtractionService} from '../../../services';
import {Subscription} from 'rxjs/Subscription';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {AsyncStateManager} from '../../async-state-manager';
import {AbstractDestructionSubject} from '../../abstract-destruction-subject';
import {takeUntil} from 'rxjs/operators';

@Component({
             moduleId   : module.id + '',
             selector   : 'inline-table-main-entity',
             templateUrl: 'sis-inline-table-main-entity.component.html',
           })
export class SisInlineTableMainEntityComponent extends AbstractDestructionSubject implements OnInit, OnDestroy {

  private _id: string;
  private _value: MainEntity;
  private _valueChange: EventEmitter<MainEntity> = new EventEmitter();
  private _readOnly = false;
  private _mainEntityNameFields: string[];
  private _field: MetaField;
  private _instanceField: MetaField;
  private _mainEntityName: string;
  private _asyncState = new AsyncStateManager();

  constructor(private readonly _kbService: KbService,
              private readonly _deService: DeService,
              private readonly _currentLanguageProvider: CurrentLanguageProvider) {
    super();
  }

  get id(): string {
    return this._id;
  }

  @Input()
  set id(value: string) {
    this._id = value;
  }

  get field(): MetaField {
    return this._field;
  }

  @Input()
  set field(value: MetaField) {
    this._field = value;
  }

  get mainEntityNameFields(): string[] {
    return this._mainEntityNameFields;
  }

  get mainEntityName(): string {
    return this._mainEntityName;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  @Input()
  set readOnly(value: boolean) {
    this._readOnly = value;
  }

  get value(): MainEntity {
    return this._value;
  }

  @Input()
  set value(value: MainEntity) {
    this._value = value;
  }

  @Output()
  get valueChange(): EventEmitter<MainEntity> {
    return this._valueChange;
  }

  public ngOnInit(): void {
    this._asyncState.addSubscription('initInstanceField', this.initInstanceField());
    this._asyncState.addSubscription('initNameFields', this.initNameFields());
    if (!this._value) {
      this._asyncState.addSubscription('initValueIfNotReceivedFromParent', this.initValueIfNotReceivedFromParent());
    }
  }

  public isReady(): boolean {
    return this._asyncState.isReady();
  }

  public ngOnDestroy(): void {
    this._asyncState.destroy();
  }

  public mainEntityChanged(instanceId: number) {
    this._value.getProperty(this._instanceField.getMetaFieldId().getSystemName()).value = instanceId;
    this._valueChange.emit(this._value);
  }

  private initValueIfNotReceivedFromParent(): Subscription {
    return this._deService.createBlankEntity(this._field.getCompoundCategorySystemName())
               .pipe(takeUntil(this.destroySubject$))
               .subscribe(blankEntity => {
                 this._value = blankEntity;
                 this._valueChange.emit(blankEntity);
                 this._asyncState.setReady('initValueIfNotReceivedFromParent');
               }, console.error);
  }

  private initInstanceField(): Subscription {
    return this._kbService.getMetaFields(this._field.getCompoundCategorySystemName())
               .pipe(takeUntil(this.destroySubject$))
               .subscribe(fields => {
                 this._instanceField = fields.find(field => field.getType() === MetaFieldType.INTEGER_INSTANCE);
                 this._asyncState.setReady('initInstanceField');
               }, console.error);
  }

  private initNameFields(): Subscription {
    return combineLatest(this._currentLanguageProvider.getCurrentLanguage(),
                         this._kbService.getMetaCategoryByMetaCategoryId(
                           new MetaCategoryId(this._field.getCompoundCategorySystemName(),
                           )))
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(
        ([language, metaCategory]) => {
          this._mainEntityNameFields =
            metaCategory.getNameMetaFieldIds().map(value => value.getSystemName());
          if (this._value) {
            this._mainEntityName = this._mainEntityNameFields
                                       .map(fieldId => ExtractionService.getStringFromField(
                                         this._value,
                                         fieldId,
                                         language.getId()),
                                       ).join(' ');
          }
          this._asyncState.setReady('initNameFields');
        }, console.error);
  }

}
