import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Entity} from '@synisys/idm-de-core-frontend';
import {Observable} from 'rxjs/Observable';
import {CoolDown} from '../utilities';

@Component({
    moduleId: module.id + '',
    selector: 'save-cancel-button',
    templateUrl: 'save-cancel-button-group.component.html',
    styleUrls: ['save-cancel-button-group.component.scss'],
})
@ControlMetadata({
    template: `
                    <save-cancel-button [id]="'%{id}'" [entity]="%{entity}" [systemName]="%{systemName}"
                        (cancelEvent)="%{cancelEvent}" (saveEvent)="%{saveEvent}" (saveCloseEvent)="%{saveCloseEvent}">
                    </save-cancel-button>
      `,
})
export class SaveCancelButtonGroupComponent {
    @Input()
    public id: string;

    @Input()
    public entity: Entity;

    @Input()
    public systemName: string;

    @Input()
    public actionNotifier: Observable<any>;

    @Output()
    public cancelEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public saveEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public saveCloseEvent: EventEmitter<void> = new EventEmitter<void>();

    public onCancelEvent() {
        this.cancelEvent.emit();
    }

    @CoolDown(300)
    public onSaveEvent() {
        this.saveEvent.emit();
    }

    @CoolDown(300)
    public onSaveCloseEvent() {
        this.saveCloseEvent.emit();
    }
}
