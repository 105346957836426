import {Component, Input, OnInit} from '@angular/core';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';
import {TooltipSettings} from '@synisys/idm-ng-controls';
import {first, tap} from 'rxjs/operators';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {noop} from 'rxjs/util/noop';
import {isArray, isEmpty, isNil} from 'lodash';
import './inline-sis-table-settings.component.scss';
import {FormType} from '@synisys/idm-dynamic-layout-interpreter';

@Component({
    moduleId: module.id + '',
    selector: 'inline-sis-table-settings',
    templateUrl: 'inline-sis-table-settings.component.html',
})
@ControlMetadata({
    template: `
                <inline-sis-table-settings [state]="%{state}" [languages]="%{languages}"
                    [formType]="%{formType}"
                    [currentLanguageId]="%{currentLanguageId}" [controlLabel]="%{controlLabel}"
                    [fields]="%{fields}">
                </inline-sis-table-settings>`,
})
export class InlineSisTableSettingsComponent extends BaseSettings
    implements OnInit {
    public isAddable = true;
    public isDeletable = true;
    public colSystemNames: string[] = [];
    public allColumns: ColumnDataGroup[] = [];

    public allMetaFields: MetaField[] = [];
    @Input()
    public formType: FormType;

    private _subEntityMetaField: MetaField;

    constructor(
        private communicationService: CommunicationService,
        private kbService: KbService
    ) {
        super(communicationService);
    }

    @Input()
    set state(state: object) {
        this.isAddable = state.hasOwnProperty('isAddable')
            ? typeof state['isAddable'] === 'string'
                ? state['isAddable'] === 'true'
                : state['isAddable']
            : null;
        this.isDeletable = state.hasOwnProperty('isDeletable')
            ? typeof state['isDeletable'] === 'string'
                ? state['isDeletable'] === 'true'
                : state['isDeletable']
            : null;
        this.colSystemNames =
            state.hasOwnProperty('colSystemNames') &&
            isArray(state['colSystemNames']) &&
            !isEmpty(state['colSystemNames'])
                ? state['colSystemNames']
                : this.generateColumnDataItems(this.allMetaFields).map(
                      item => item.value.id
                  );

        if (state['tooltipSettings']) {
            this._tooltipSettings = TooltipSettings.fromJsonString(
                state['tooltipSettings']
            );
        }
        if (state['tooltipSettings']) {
            this._tooltipSettings = TooltipSettings.fromJsonString(
                state['tooltipSettings']
            );
        }
        if (state['controlLabel']) {
            this._controlLabel = state['controlLabel'];
        }
    }

    @Input()
    set fields(fields: {field: MetaField | undefined}) {
        this._subEntityMetaField = fields.field;
    }

    public ngOnInit(): void {
        if (this._subEntityMetaField) {
            const compoundCategorySystemName = this._subEntityMetaField
                .getCompoundCategorySystemName
                ? this._subEntityMetaField.getCompoundCategorySystemName()
                : this._subEntityMetaField['compoundCategorySystemName'];

            this.kbService
                .getMetaFields(compoundCategorySystemName)
                .pipe(
                    first(),
                    tap((metaFields: MetaField[]) => {
                        this.allMetaFields = metaFields.filter(
                            (metafield: MetaField) => {
                                return (
                                    metafield.getType() ===
                                        MetaFieldType.DATE ||
                                    metafield.getType() ===
                                        MetaFieldType.CLASSIFIER ||
                                    metafield.getType() ===
                                        MetaFieldType.MAIN_ENTITY ||
                                    metafield.getType() ===
                                        MetaFieldType.MULTILINGUAL_STRING ||
                                    metafield.getType() ===
                                        MetaFieldType.DOCUMENT ||
                                    metafield.getType() ===
                                        MetaFieldType.STRING ||
                                    metafield.getType() ===
                                        MetaFieldType.BIG_DECIMAL ||
                                    metafield.getType() ===
                                        MetaFieldType.DECIMAL ||
                                    metafield.getType() ===
                                        MetaFieldType.INTEGER ||
                                    metafield.getType() ===
                                        MetaFieldType.ACCOUNTING ||
                                    metafield.getType() ===
                                        MetaFieldType.MONEY ||
                                    metafield.getType() ===
                                        MetaFieldType.BOOLEAN
                                );
                            }
                        );

                        const allItems = this.generateColumnDataItems(
                            this.allMetaFields
                        );

                        this.allColumns.push({
                            items: allItems,
                            name: compoundCategorySystemName,
                        });

                        if (
                            isNil(this.colSystemNames) ||
                            isEmpty(this.colSystemNames)
                        ) {
                            this.colSystemNames = allItems.map(
                                item => item.value.id
                            );
                        }
                    })
                )
                .subscribe(noop, console.error);
        }
    }

    public changeIsAddable(value: boolean): void {
        this.isAddable = value;
        this.changeSettings();
    }

    public changeIsDeletable(value: boolean): void {
        this.isDeletable = value;
        this.changeSettings();
    }

    public changeColSystemNames(value: string[]): void {
        this.colSystemNames = value;
        this.changeSettings();
    }

    public onOrderChange(value: string[]): void {
        this.colSystemNames = value;
        this.changeSettings();
    }

    public isEditForm(): boolean {
        return this.formType === FormType.EDIT;
    }

    private generateColumnDataItems(metafields: MetaField[]): ColumnData[] {
        return metafields.map((field, index) => {
            return {
                name: field
                    .getDisplayNameMultilingual()
                    .getValue(this.currentLanguageId),
                value: {
                    name: field
                        .getDisplayNameMultilingual()
                        .getValue(this.currentLanguageId),
                    id: field.getSystemName(),
                },
            };
        });
    }

    private changeSettings(): void {
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                bindings: {},
                state: {
                    isAddable: this.isAddable,
                    isDeletable: this.isDeletable,
                    colSystemNames: this.colSystemNames,
                },
            })
        );
    }
}

export interface ColumnData {
    name: string;
    value: {
        name: string;
        id: string;
    };
}

export interface ColumnDataGroup {
    items: ColumnData[];
    name: string;
}
