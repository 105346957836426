import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {MetaCategoryId} from '@synisys/idm-kb-service-client-js';

export type ClassifierChange = [MetaCategoryId, number, number];

@Injectable()
export class SisInlineCommunicationService {

  private readonly _classifierSubject = new Subject<ClassifierChange>();
  public readonly classifierObservable: Observable<ClassifierChange> = this._classifierSubject.asObservable();

  public publishClassifierChange(change: ClassifierChange) {
    this._classifierSubject.next(change);
  }

}
