/**
 * @author Vahagn Lazyan.
 * @since 1.3.0
 */
import {Injectable, OnDestroy} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Subject} from 'rxjs/Subject';

import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity, FilterBuilder, FilterCriteria, PagingOptions, SortingOptions} from '@synisys/idm-de-core-frontend';
import {DeService, MainEntity, PortfolioService, ServiceResponse} from '@synisys/idm-de-service-client-js';
import {FormResolverService} from '@synisys/idm-frontend-shared';
import {
  KbService,
  MetaCategory,
  MetaCategoryId,
  MetaField,
  MetaFieldId,
  MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {CategoryPermissionType} from '@synisys/idm-um-permission-client-js';

import {PortfolioRowModel} from './portfolio-row.model';
import {PortfolioCellModel} from './portfolio-cell.model';
import {DynamicCommunicationService, PortfolioCommunicationDto} from '../../services';

@Injectable()
export class DynamicPortfolioService implements OnDestroy {

  /**
   * Current Language.
   * Used for extracting values from {@link MultilingualString}.
   * @type {number}
   * @private
   */
  private _currentLanguageId: number;

  /**
   * Count of all pages in portfolio.
   * @type {number}
   * @private
   */
  private _pageTotalCount: number;

  /**
   * PortfolioColumns list, for rendering portfolio column headers.
   * @type {MetaField[]}
   * @private
   */
  private _portfolioColumns: MetaField[];

  /**
   * Array of {@link PortfolioRowModel} containing {@link PortfolioCellModel}s to render every cell,
   * depending on its type and value.
   * @type {PortfolioRowModel[]}
   * @private
   */
  private _portfolioRows: PortfolioRowModel[];

  private _searchNames: Map<string, string> = new Map();

  /**
   * Count of all items, for specified {@link categorySystemName}.
   * @type {number}
   * @private
   */
  private _totalItemsCount: number;

  /**
   * Filter options to load portfolioItems with.
   * @type {object}
   * @private
   */
  private filterOptions: object | undefined;

  /**
   * Map containing filters for portfolio items.
   * @type {Map<any, any>}
   * @default
   * @private
   */
  private filters: Map<string, FilterCriteria> = new Map();

  /**
   * Sorting options, used for getting portfolio items sorted.
   * @type {SortingOptions}
   * @default
   * @private
   */
  private _sortingOptions: SortingOptions | undefined = undefined;

  private categoryPermissionTypes: CategoryPermissionType[];

  private _portfolioRowsUpdateEvent: ReplaySubject<string> = new ReplaySubject(1);

  private nameMetaFieldIds: MetaFieldId[];

  private categorySystemName: string;
  private pageLimit: number;

  private portfolioProperties$: Subscription;
  private quickFilter$: Subscription;

  private columnFields: ColumnField[] = [];

  constructor(private portfolioService: PortfolioService,
              private currentLanguageProvider: CurrentLanguageProvider,
              private kbService: KbService,
              private dynamicCommunicationService: DynamicCommunicationService,
              private formResolverService: FormResolverService,
              private deService: DeService) {
  }

  get portfolioRowsUpdateEvent(): ReplaySubject<string> {
    return this._portfolioRowsUpdateEvent;
  }

  get currentLanguageId(): number {
    return this._currentLanguageId;
  }

  get pageTotalCount(): number {
    return this._pageTotalCount;
  }

  get portfolioColumns(): MetaField[] {
    return this._portfolioColumns;
  }

  get portfolioRows(): PortfolioRowModel[] {
    return this._portfolioRows;
  }

  get searchNames(): Map<string, string> {
    return this._searchNames;
  }

  set sortingOptions(options: SortingOptions) {
    this._sortingOptions = options;
  }

  get totalItemsCount(): number {
    return this._totalItemsCount;
  }

  public initFor(categorySystemName: string, columnSystemNames: string[],
                 pageLimit: number, filterKeys: string[], defaultFilter?: FilterCriteria, categoryPermissions?:
                   CategoryPermissionType[]): Observable<boolean> {

    if (defaultFilter) {
      this.filterOptions = defaultFilter.toJson();
      this.filters.set('default', defaultFilter);
    }
    this.categorySystemName = categorySystemName;
    this.pageLimit = pageLimit;

    this.categoryPermissionTypes = categoryPermissions;

    this.columnFields = this.processNestedColSystemNames(columnSystemNames);

    const entities$ = this.portfolioService.loadPortfolioItems(categorySystemName,
                                                               new PagingOptions(0, pageLimit),
                                                               undefined,
                                                               this.filterOptions, categoryPermissions);
    const metaFields$ = this.processColumnMetaFields(this.columnFields, this.categorySystemName);
    const metaCategory$ = this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName));
    const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();

    return Observable.combineLatest(entities$, metaFields$, metaCategory$, currentLanguage$)
                     .map((data: [ServiceResponse<Entity[]>, MetaField[], MetaCategory, Language]): boolean => {
                       const [response, fields, category, language] = data;
                       this.processMetaFrom(response);

                       this._portfolioColumns = fields;
                       this.nameMetaFieldIds = category.getNameMetaFieldIds();
                       this._currentLanguageId = language.getId();
                       this.processSearchFields(fields);
                       this._portfolioRows = this.extractColumns(this._portfolioColumns, response.getData(),
                                                                 this.nameMetaFieldIds, this.columnFields);

                       this._portfolioRowsUpdateEvent.next(this.categorySystemName);

                       this.subscribeToFilters(filterKeys);
                       return true;
                     });

  }

  public ngOnDestroy(): void {
    this.portfolioProperties$ && this.portfolioProperties$.unsubscribe();
    this.quickFilter$ && this.quickFilter$.unsubscribe();
  }

  /**
   * @deprecated
   */
  public destroy(): void {
    console.warn('method is deprecated');
  }

  /**
   * Navigates to date entry form.
   * @param {number} entityInstanceId
   * @param {string} formName
   * @param {number} [formId=0]
   */
  public navigateToDeForm(entityInstanceId: number, formName: string, formId: number = 0): Observable<boolean> {
    const isAlreadyDeleted$: Subject<boolean> = new Subject<boolean>();
    this.deService.loadEntityByInstanceId(this.categorySystemName, entityInstanceId).subscribe(() => {
      isAlreadyDeleted$.next(false);
      entityInstanceId !== undefined
      ? this.formResolverService.navigateToViewDeForm(this.categorySystemName, formName, entityInstanceId, formId)
      : this.formResolverService.navigateToCreateNewDeForm(this.categorySystemName, formName, formId);
    }, error => {
      if (error.status === 404) {
        isAlreadyDeleted$.next(true);
      }
    });
    return isAlreadyDeleted$;
  }

  public updatePortfolio(paging: PagingOptions): void {
    this.loadPortfolioItems(paging, this._sortingOptions, this.filterOptions, this.categoryPermissionTypes)
        .subscribe(_ => {
        }, console.error);
  }

  private createFilterFrom(filters: Map<string, FilterCriteria>): object | undefined {
    let filter: FilterCriteria;
    if (filters.size === 1) {
      filter = filters.values().next().value;
    } else {
      const builder = new FilterBuilder();
      Array.from(filters.entries()).forEach(value => {
        if (value[1] !== null && Object.keys(value[1].toJson()).length !== 0) {
          builder.and(value[1]);
        }
      });
      filter = builder.build();
    }
    return (filter === undefined || filter === null) ? undefined : filter.toJson();
  }

  private extractColumns(portfolioFields: MetaField[],
                         entities: Entity[],
                         nameMetaFieldIds: MetaFieldId[],
                         columnFields: ColumnField[]): PortfolioRowModel[] {
    return entities.map((entity: Entity) => {
      const cells: PortfolioCellModel[] = columnFields.map((columnField: ColumnField) => {
        const isNameField: boolean = nameMetaFieldIds.find((metaFieldId: MetaFieldId) => {
          return metaFieldId.getSystemName() === columnField.systemName;
        }) !== undefined;
        if (columnField.isNested) {
          const foundColumn = portfolioFields.find(
            portfolioField => columnField.nestedFieldSystemName === portfolioField.getSystemName()
          );
          if (!foundColumn) {
            throw new Error(`field ${columnField.nestedFieldSystemName} was not found in portfolio fields`);
          } else if (!columnField.nestedFieldSystemName) {
            throw new Error('nested field system name of nested field was undefined');
          } else {
            const columnMetaField: MetaField = foundColumn;
            if (entity.getProperty<MainEntity>(columnField.systemName).value) {
              return new PortfolioCellModel(columnMetaField,
                                            entity.getProperty<MainEntity>(columnField.systemName).value
                                                  .getProperty(columnField.nestedFieldSystemName).value,
                                            isNameField);
            } else {
              return new PortfolioCellModel(columnMetaField, this.extractValueFromField(columnMetaField), isNameField);
            }
          }
        } else {
          const foundColumn: MetaField | undefined = portfolioFields.find(
            metaField => columnField.systemName === metaField.getSystemName()
          );
          if (!foundColumn) {
            throw new Error(`field ${columnField.systemName} was not found in portfolio fields`);
          }
          return new PortfolioCellModel(foundColumn, entity.getProperty(foundColumn.getSystemName()).value,
                                        isNameField);
        }
      });
      return new PortfolioRowModel(cells, entity.getInstanceId());
    });
  }

  /**
   * Loads values from {@link PortfolioService} by paging, sorting and {@link categorySystemName}.
   * Updates {@link _portfolioRows}, which will cause changes in view.
   * @param {PagingOptions} pagingOptions
   * @param {SortingOptions} sortingOptions
   * @param {object} [filters]
   * @private
   */
  private loadPortfolioItems(pagingOptions: PagingOptions, sortingOptions?: SortingOptions,
                             filters?: object, categoryPermissionTypes?: CategoryPermissionType[]): Observable<void> {
    return this.portfolioService.loadPortfolioItems(this.categorySystemName, pagingOptions, sortingOptions, filters,
                                                    categoryPermissionTypes)
               .map((data: ServiceResponse<Entity[]>) => {
                 this.processMetaFrom(data);
                 this._portfolioRows = this.extractColumns(this._portfolioColumns, data.getData(),
                                                           this.nameMetaFieldIds, this.columnFields);
                 this._portfolioRowsUpdateEvent.next(this.categorySystemName);
               });
  }

  /**
   * Processes metadata from {@link ServiceResponse}.
   * Gets all items count and calculates pages count.
   * @param {ServiceResponse<any>} serviceResponse
   * @private
   */
  private processMetaFrom(serviceResponse: ServiceResponse<object>): void {
    this._totalItemsCount = serviceResponse.getMeta().getTotalRowCount();
    this._pageTotalCount = Math.ceil(this._totalItemsCount / this.pageLimit);
  }

  private processSearchFields(allFields: MetaField[]): void {
    this._searchNames = new Map();
    allFields.forEach((field: MetaField) => {
      if (field.getType() === MetaFieldType.MULTILINGUAL_STRING) {
        this._searchNames.set(field.getSystemName(), `${field.getSystemName()}.${this._currentLanguageId}`);
      } else if (!this._searchNames.has(field.getSystemName())) {
        this._searchNames.set(field.getSystemName(), field.getSystemName());
      }
    });
  }

  private subscribeToFilters(filterKeys: string[]): void {
    this.portfolioProperties$ = this.dynamicCommunicationService.portfolioPropertiesObservable.filter(dto => {
      return filterKeys.find((key: string) => {
        return key === dto.key;
      }) !== undefined;
    }).mergeMap(
      (dto: PortfolioCommunicationDto) => {
        this.filters.set(dto.key, dto.data as FilterCriteria);
        this.filterOptions = this.createFilterFrom(this.filters);
        return this.loadPortfolioItems(new PagingOptions(0, this.pageLimit),
                                       this._sortingOptions,
                                       this.filterOptions, this.categoryPermissionTypes);
      }).catch((err, o) => {
      console.error(err);
      return o;
    }).subscribe(() => {}, err => console.error(err));
    this.quickFilter$ = this.dynamicCommunicationService.quickFilterObservable.mergeMap(
      (quickFilterMap: Map<string, FilterCriteria>) => {
        filterKeys.forEach((filterKey: string) => {
          if (quickFilterMap.has(filterKey)) {
            this.filters.set(filterKey, <FilterCriteria>quickFilterMap.get(filterKey));
          }
        });
        this.filterOptions = this.createFilterFrom(this.filters);
        return this.loadPortfolioItems(new PagingOptions(0, this.pageLimit),
                                       this._sortingOptions,
                                       this.filterOptions, this.categoryPermissionTypes);
      }).catch((err, o) => {
      console.error(err);
      return o;
    }).subscribe(() => {}, err => console.error(err));
  }

  private processNestedColSystemNames(colSystemNames: string[]): ColumnField[] {
    return colSystemNames.map((item: string) => {
      const splits: string[] = item.split('.');
      return {
        isNested             : splits.length > 1,
        nestedFieldSystemName: splits.length > 1 ? splits[1] : undefined,
        systemName           : splits[0],
      };
    });
  }

  private processColumnMetaFields(columnFields: ColumnField[], categorySystemName: string): Observable<MetaField[]> {
    return this.kbService.getMetaFields(categorySystemName)
               .mergeMap((metaFields: MetaField[]) => {
                 return Observable.zip(...columnFields.map((columnField: ColumnField) => {
                   if (!columnField.isNested) {
                     const columnMetaField = metaFields.find(metaField => {
                       return metaField.getSystemName() === columnField.systemName;
                     });
                     if (!columnMetaField) {
                       throw new Error(`no field found for ${columnField.systemName} column`);
                     }
                     return Observable.of(columnMetaField);
                   } else {
                     return this.kbService.getMainEntityMetaFields(categorySystemName, columnField.systemName)
                                .map((mainEntityMetaFields: MetaField[]) => {
                                  const mainEntityMetaField = mainEntityMetaFields.find(metaField => {
                                    return metaField.getSystemName() === columnField.nestedFieldSystemName;
                                  });
                                  if (!mainEntityMetaField) {
                                    throw new Error(`no field found for ${columnField.systemName} column`);
                                  }
                                  return mainEntityMetaField;
                                });
                   }
                 }));
               });
  }

  private extractValueFromField(metaField: MetaField): MultilingualString | undefined {
    return metaField.getType() === MetaFieldType.MULTILINGUAL_STRING ?
           MultilingualString.newBuilder().withValueForLanguage(this.currentLanguageId, '').build() : undefined;
  }

}

interface ColumnField {
  isNested: boolean;
  systemName: string;
  nestedFieldSystemName?: string;
}
