/**
 * @author Vahagn Lazyan.
 * @since 1.1.0
 */
import {PortfolioCellModel} from './portfolio-cell.model';

export class PortfolioRowModel {

  constructor(private _cells: PortfolioCellModel[], private _entityInstanceId: number) {
  }

  get cells(): PortfolioCellModel[] {
    return this._cells;
  }

  get entityInstanceId(): number {
    return this._entityInstanceId;
  }
}
