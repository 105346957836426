/**
 * @author Vahagn Lazyan.
 * @since 1.1.0
 */
import {MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';

export class PortfolioCellModel {

  constructor(private _metaField: MetaField,
              private _fieldValue: object | undefined,
              private _isNameField: boolean) {
  }

  public get compoundCategorySystemName(): string {
    return this._metaField.getCompoundCategorySystemName();
  }

  public get fieldType(): MetaFieldType {
    return this._metaField.getType();
  }

  public get fieldValue(): object | undefined {
    return this._fieldValue;
  }

  public get isNameField(): boolean {
    return this._isNameField;
  }

}
