import {catchError, filter, first, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {KbService, MetaCategoryId, MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {Classifier, ClassifierService, ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {SisInlineCommunicationService} from '../sis-inline-communication.service';
import {Entity} from '@synisys/idm-de-core-frontend';
import {AbstractDestructionSubject} from '../../abstract-destruction-subject';
import {of} from 'rxjs/observable/of';
import {Observable} from 'rxjs/Observable';

type ParentFieldAndValue = [MetaCategoryId, (number | undefined)] | undefined;

@Component({
             moduleId   : module.id + '',
             selector   : 'inline-table-combo-box',
             templateUrl: 'sis-inline-table-combo-box.component.html',
           })
export class SisInlineTableComboBoxComponent extends AbstractDestructionSubject implements OnInit, OnDestroy {

  private _id: string;
  private _readOnly: boolean;
  private _field: MetaField;
  private _parent: MetaCategoryId | undefined;
  private _parentId: number | undefined;
  private _isChild = false;
  private _items: ClassifierView[];
  private _isDisabled = false;
  private _selectedValue: Classifier | undefined;
  private _selectedValueChange: EventEmitter<Classifier> = new EventEmitter();
  private _selectedValueName: string;
  private _columnFields: MetaField[];
  private _entity: Entity;

  constructor(private readonly communicationService: SisInlineCommunicationService,
              private readonly classifierService: ClassifierService,
              private readonly kbService: KbService) {
    super();
  }

  get id(): string {
    return this._id;
  }

  @Input()
  set id(value: string) {
    this._id = value;
  }

  @Input()
  set columnFields(value: MetaField[]) {
    this._columnFields = value;
  }

  @Input()
  set entity(value: Entity) {
    this._entity = value;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input()
  set isDisabled(value: boolean) {
    this._isDisabled = !!value;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  @Input()
  set readOnly(value: boolean) {
    this._readOnly = value;
  }

  get field(): MetaField {
    return this._field;
  }

  @Input()
  set field(value: MetaField) {
    this._field = value;
  }

  get items(): ClassifierView[] {
    return this._items;
  }

  get selectedValue(): Classifier {
    return this._selectedValue;
  }

  @Input()
  set selectedValue(value: Classifier) {
    this._selectedValue = value;
  }

  @Output()
  get selectedValueChange(): EventEmitter<Classifier | undefined> {
    return this._selectedValueChange;
  }

  get selectedValueName(): string {
    return this._selectedValueName;
  }

  public ngOnInit(): void {
    this.findParent().pipe(
      mergeMap(parentData => {
        if (parentData) {
          [this._parent, this._parentId] = parentData;
          this._isChild = true;
        }
        return this.loadItems(this._parentId);
      }),
      takeUntil(this.destroySubject$),
    ).subscribe(() => {
      this.initSelectedValueName();
      this.proceedWithChildSubscription();
    }, console.error);
  }

  public itemChanged(selectedId: number | undefined) {
    if (selectedId) {
      this.classifierService.loadClassifier(this._field.getCompoundCategorySystemName(), selectedId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(classifier => {
            this._selectedValue = classifier;
            this._selectedValueChange.emit(classifier);
            this.communicationService.publishClassifierChange(
              [
                new MetaCategoryId(this._field.getCompoundCategorySystemName()),
                this._entity.getId(),
                classifier.getId(),
              ],
            );
          }, console.error);
      this._selectedValueName = this.items.find(item => item.getId() === selectedId).getName();
    } else {
      this._selectedValue = undefined;
      this._selectedValueChange.emit(null);
      this.communicationService.publishClassifierChange(
        [new MetaCategoryId(this._field.getCompoundCategorySystemName()), this._entity.getId(), undefined]);
      this._selectedValueName = undefined;
    }
  }

  private proceedWithChildSubscription() {
    if (this._isChild) {
      this.communicationService.classifierObservable.pipe(
        filter(
          ([category, entityId]) => category.getSystemName() === this._parent.getSystemName()
            && this._entity.getId() === entityId
        ),
        mergeMap(([, , id]) => {
          this.itemChanged(undefined);
          return this.loadItems(id);
        }),
        takeUntil(this.destroySubject$)
      ).subscribe(() => {
      }, console.error);
    }
  }

  private initSelectedValueName() {
    if (this._selectedValue) {
      const selectedItem = this._items.find(item => {
        return item.getId() === this._selectedValue.getId();
      });
      if (selectedItem) {
        this._selectedValueName = selectedItem.getName();
      } else {
        this.classifierService.getEntityName(this.field.getCompoundCategorySystemName(), this._selectedValue)
            .pipe(
              first(),
              map(name => this._selectedValueName = name),
              catchError(err => {
                this._selectedValueName = '';
                console.error(err);
                throw new Error('selected classifier was not found');
              }),
            ).subscribe(console.log, console.error);
      }
    }
  }

  private loadItems(parentId?: number): Observable<void> {
    if (this._isChild && parentId) {
      return this.classifierService.loadClassifiersViewByParent(
        this.field.getCompoundCategorySystemName(), parentId,
      ).pipe(map(value => {
        this._isDisabled = false;
        this._items = value;
      }));
    } else if (this._isChild) {
      this._isDisabled = true;
      this._items = [];
      return of(undefined).pipe(map(() => {
        return;
      }));
    } else {
      return this.classifierService.loadClassifiersView(this.field.getCompoundCategorySystemName()).pipe(
        map(value => {
          this._items = value;
        }));
    }
  }

  private findParent(): Observable<ParentFieldAndValue> {
    return this.kbService.getMetaFields(this._field.getCompoundCategorySystemName()).pipe(
      map((classifierMetaFields: MetaField[]) => {
        return classifierMetaFields.find(
          (item: MetaField) => item.getType() === MetaFieldType.PARENT,
        );
      }),
      map(parent => {
        if (parent === undefined) {
          return undefined;
        }
        return this._columnFields.find(
          (item: MetaField) =>
            item.getCompoundCategorySystemName() === parent.getCompoundCategorySystemName(),
        );
      }),
      map(parentField => {
        if (parentField) {
          const parentClassifier = <Classifier | undefined>this._entity.getProperty(
            parentField.getMetaFieldId().getSystemName()).value;
          return <[MetaCategoryId, number | undefined]>[
            new MetaCategoryId(parentField.getCompoundCategorySystemName()),
            parentClassifier ? parentClassifier.getId() : undefined,
          ];
        } else {
          return undefined;
        }
      }));
  }
}
