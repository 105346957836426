import {NativeDateAdapter} from '@angular/material';
import {isString} from 'lodash';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomNativeDateAdapter extends NativeDateAdapter {

  public parse(value: number | string): Date | null {

    let timestamp;
    if (isString(value) && (value.indexOf('/') > -1)) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if (isString(value)) {
      timestamp = Date.parse(value);
    } else {
      timestamp = value;
    }
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  public format(date: Date, displayFormat: Object): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

}
